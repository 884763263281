import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Container } from "./Container"
import { mediaQueries } from "./breakpoints"
import { MenuLink } from "./MenuLink"
import logo from "../images/logo.svg"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

const Navbar = styled("header")`
  position: relative;
  z-index: 2;
  padding-top: var(--gutterWidth);
  padding-bottom: var(--gutterWidth);
`

const NavbarContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const LargeNavbarLinks = styled("ul")`
  display: flex;
  flex-wrap: wrap;
  text-align: right;
  list-style: none;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
  ${mediaQueries.large(
    css`
      flex-direction: row;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
    `
  )}

  li:not(:first-of-type) {
    margin-left: var(--gutterWidth);
  }
`

const Logo = styled("img")`
  display: block;
  max-width: 11.375rem;
  padding-top: .5rem;
  ${mediaQueries.large(
    css`
      padding-top: 0;
    `
  )}
`

class HeaderMenu extends React.Component {

  render() {
    const { data } = this.props
    const { edges: events } = data.allMarkdownRemark

    return (
      <Navbar>
        <NavbarContainer>
          <Link to="/">
            <Logo src={logo} alt="Logo Misesova akademie" />
          </Link>
          <LargeNavbarLinks>
            {events &&
              events.map(({ node: event }) => (
              <>
                {event.frontmatter.speakers &&
                event.frontmatter.speakers.length ? (
                  <li>
                    <MenuLink to="/#prednasejici">Přednášející</MenuLink>
                  </li>
                ) : null }
                </>
              )
            )}
            {events &&
              events.map(({ node: event }) => (
              <>
                {((event.frontmatter.programActive === true) || (event.frontmatter.programSimple)) && (
                  <>
                    {((event.frontmatter.programThursday) || (event.frontmatter.programFriday) || (event.frontmatter.programSaturday) || (event.frontmatter.programSunday) || (event.frontmatter.programSimple)) && (
                      <li>
                        <MenuLink to="/#program">Program</MenuLink>
                      </li>
                    )}
                  </>
                )}
              </>
              )
            )}
            {events &&
              events.map(({ node: event }) => (
              <>
                {event.frontmatter.documents &&
                event.frontmatter.documents.length ? (
                  <li>
                    <MenuLink to="/#podklady">Podklady</MenuLink>
                  </li>
                ) : null }
                </>
              )
            )}
            <li>
              <MenuLink to="/#absolventi">Absolventi</MenuLink>
            </li>
            <li>
              <MenuLink to="/#partneri">Partneři</MenuLink>
            </li>
            <li>
              <MenuLink to="/#historie">Historie</MenuLink>
            </li>
            <li>
              <MenuLink to="/#kontakt">Kontakt</MenuLink>
            </li>
          </LargeNavbarLinks>
        </NavbarContainer>
      </Navbar>
    )
  }
}

HeaderMenu.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query HeaderMenuQuery {
        allMarkdownRemark(
          limit: 1
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              registrationStatus: { in: [ "preparation", "open", "close", "postponed" ]}
              templateKey: { eq: "event" }
            }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                programActive
                programSimple
                documents {
                  documentSpeaker
                }
                speakers {
                  speakerName
                }
                programThursday {
                  programTimeStart
                }
                programFriday {
                  programTimeStart
                }
                programSaturday {
                  programTimeStart
                }
                programSunday {
                  programTimeStart
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <HeaderMenu data={data} count={count} />}
  />
)
