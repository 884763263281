import { css } from "@emotion/react"

export const breakPoints = {
  breakPoints: {
    base: 0,
    small: 576,
    medium: 768,
    large: 992,
    extraLarge: 1200
  }
}

const andMaxWidth = (max?: number) => (max ? ` and (max-width: ${max}px)` : "")

export const mediaQueries = {
  base: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 0px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  small: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 576px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  smallDown: (innerCss: any, max?: number) =>
    css`
      @media (max-width: 575px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  medium: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 768px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  mediumDown: (innerCss: any, max?: number) =>
    css`
      @media (max-width: 767px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  large: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 992px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  largeDown: (innerCss: any, max?: number) =>
    css`
      @media (max-width: 991px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  extraLarge: (innerCss: any) =>
    css`
      @media (min-width: 1200px) {
        ${innerCss};
      }
    `
}
