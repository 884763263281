import { css } from "@emotion/react"
import React from "react"
import { mediaQueries } from "./breakpoints"

interface Props {
  children: any
  id?: string
  className?: string
  narrow?: boolean
  about?: boolean
  jumbotron?: boolean
}

const narrowStyles = css`
  max-width: 42rem;
  text-align: center;

  h2 {
    margin-bottom: .5em;
  }

  p {
    line-height: 1.4;
  }
`

const aboutStyles = css`
  max-width: 42rem;

  h2 {
    text-align: left;
    ${mediaQueries.smallDown(
      css`
        text-align: center;
      `
    )}
  }
`

const jumbotronStyles = css`
  h2 {
    font-size: var(--h3);
    font-weight: 400;
    text-align: left !important;
    margin-bottom: .5em;
  }
`

export const Container = (props: Props) => {
  const containerStyles = [
    css`
      max-width: var(--containerWidth);
      margin-left: auto;
      margin-right: auto;
      padding-left: var(--gutterWidth);
      padding-right: var(--gutterWidth);

      h2 {
        margin-bottom: 0.5em;
      }
    `
  ]

  return (
    <div id={props.id} className={props.className} css={[containerStyles, props.narrow && narrowStyles, props.about && aboutStyles, props.jumbotron && jumbotronStyles]}>{props.children}</div>
  )
}
