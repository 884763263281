import { Global, css } from '@emotion/react'
import React from "react"

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: "PT Sans";
          src: local('PT Sans Bold'), local('PTSans-BoldItalic'), url("../fonts/PTSans-BoldItalic.woff2") format('woff2'), url("../fonts/PTSans-BoldItalic.woff") format("woff");
          font-weight: bold;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: "PT Sans";
          src: local('PT Sans Bold'), local('PTSans-Bold'), url("../fonts/PTSans-Bold.woff2") format('woff2'),
            url("../fonts/PTSans-Bold.woff") format("woff");
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: "PT Sans";
          src: local('PT Sans'), local('PTSans'), url("../fonts/PTSans-Regular.woff2") format('woff2'),
            url("../fonts/PTSans-Regular.woff") format("woff");
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: "PT Sans";
          src: local('PT Sans'), local('PTSans-Italic'), url("../fonts/PTSans-Italic.woff2") format('woff2'),
            url("../fonts/PTSans-Italic.woff") format("woff");
          font-weight: normal;
          font-style: italic;
          font-display: swap;
        }

        :root {
          --yellow: #F9D83D;
          --darkGray: #201D1D;
          --darkGray2: #191717;
          --white: #ffffff;
          --black: #000000;

          --h1: 2rem;
          --h2: var(--h1);
          --h3: 1.625rem;
          --h4: 1.125rem;

          --transition: .2s ease-in-out;

          --containerWidth: calc(77.5rem + var(--gutterWidth) + var(--gutterWidth));
          --gutterWidth: 2rem;
          @media screen and (max-width: 575px) {
            --gutterWidth: 1rem;
            --h2: 1.75rem;
          }
        }

        html {
          box-sizing: border-box;
          font-size: 16px;
          height: 100%;
        }

        body {
          font-size: 1em;
          font-family: "PT Sans", sans-serif;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          line-height: 1.5;
          background-color: var(--darkGray);
          color: var(--white);
        }

        button {
          font-family: "PT Sans", sans-serif;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        body,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ol,
        ul {
          margin: 0;
          padding: 0;
          font-weight: normal;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: .5em;
          font-weight: 700;
          line-height: 1.133;
        }

        h1 {
          font-size: var(--h1);
        }

        h2 {
          font-size: var(--h2);
        }

        h3 {
          font-size: var(--h3);
        }

        h4 {
          font-size: var(--h4);
        }

        p {
          margin-bottom: .75em;
        }

        a {
          color: var(--yellow);
          @media (hover: hover) {
            &:hover {
              text-decoration: none;
            }
          }
        }

        img {
          max-width: 100%;
          height: auto;
        }

        .ReactModal__Body--open {
          overflow: hidden;
          main {
            filter: blur(3px);
          }
        }
      `}
    />
  )
}
