import React from "react"
import { mediaQueries} from "./breakpoints"
import { css } from "@emotion/react"
import { Link } from "gatsby"

export const menuLinkStyles = css`
  position: relative;
  display: block;
  text-decoration: none;
  color: var(--white);
  font-weight: 700;
  transition: color var(--transition);
  padding-top: .25rem;
  padding-bottom: .25rem;
  text-shadow: 0 0 7px rgba(32, 29, 29, 1);
  ${mediaQueries.large(
    css`
      padding-top: 0;
      padding-bottom: 0;
    `
  )}

  @media (hover: hover) {
    &:hover {
      color: var(--yellow);
    }
  }
`

interface Props {
  children: React.ReactNode
  to: string
}

export const MenuLink = (props: Props) => (
  <Link css={menuLinkStyles} to={props.to}>
    {props.children}
  </Link>
)
