import { PageRendererProps } from "gatsby"
import React, { ReactNode } from "react"
import Helmet from "react-helmet"
import { GlobalStyles } from './GlobalStyles'
import Footer from '../components/Footer'
import styled from "@emotion/styled"
import SimpleReactLightbox from 'simple-react-lightbox'
import AlertMessage from "./AlertMessage"

interface Props {
  title: string
  description: string
  children: ReactNode
}

const Wrapper = styled("div")`
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
`

const Main = styled("main")`
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
`

const Layout = (props: Props) => {
  const { children, title, description } = props

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://www.misesakademie.cz/og-facebook.png?v4"
        />
        <meta property="og:url" content="/" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://www.misesakademie.cz/og-twitter.png?v4"
        />
        <meta name="twitter:image:alt" content={description} />
        <meta name="twitter:site" content="@libinstcz" />
        <meta name="twitter:creator" content="@libinstcz" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#F9D83D" />
        <meta name="msapplication-TileColor" content="#201D1D" />
        <meta name="theme-color" content="#201D1D" />
      </Helmet>
      <GlobalStyles />
      <SimpleReactLightbox>
        <AlertMessage />
        <Wrapper>
          <Main>{children}</Main>
          <Footer />
        </Wrapper>
      </SimpleReactLightbox>
    </>
  )
}

export default Layout
