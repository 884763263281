import * as React from "react"
import styled from "@emotion/styled"

interface ButtonProps {
  children: React.ReactNode
  to: string
  className?: string
  onClick?: any
}

const ButtonStyled = styled("a")`
  display: inline-block;
  padding: .75rem var(--gutterWidth);
  text-decoration: none;
  text-align: center;
  color: var(--white);
  background-color: rgba(32, 29, 29, 0.7);
  font-weight: 700;
  border: 1px solid var(--yellow);;
  transition: all .2s ease-in-out;
  @media (hover: hover) {
    &:hover {
      background-color: var(--yellow);
      color: var(--darkGray);
      border-color: var(--yellow);
    }
  }
`

class Button extends React.Component<ButtonProps> {
  render() {
    const { children, className, onClick, to } = this.props

    return (
      <ButtonStyled href={to} onClick={onClick} className={className}>
        {children}
      </ButtonStyled>
    )
  }
}

export default Button;
