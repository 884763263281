import * as React from "react"
import ReactModal from "react-modal"
import styled from "@emotion/styled"
import { css, ClassNames } from "@emotion/core"
import { mediaQueries } from "./breakpoints"
import iconInfo from "../images/info.svg"

ReactModal.setAppElement(`#___gatsby`)

interface Props {
  children: any
}

interface State {
  isOpen: boolean
}

const Button = styled("button")`
  appearance: none;
  padding: 0;
  text-align: center;
  background: transparent;
  box-shadow: none;
  border: 0;
  cursor: pointer;
`

export class Modal extends React.PureComponent<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = { isOpen: false }
  }

  handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const styles = css`
      .ReactModal__Overlay {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 9999;
        background-color: rgba(255, 255, 255, 0.05) !important;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0rem 1rem;
      }

      .ReactModal__Dialog {
        position: relative;
        border: 0;
        background-color: transparent;
        width: 100%;
        max-width: 37.5rem;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--black);
        padding: 2rem 3rem;
        pointer-events: all;
        box-shadow: 0 0 3rem 6rem rgba(0, 0, 0, 0.2);
        &:focus {
          outline: 0;
        }

        p {
          margin-top: .5rem;
        }
      }

      .ReactModal__Content {
        display: flex;
        align-items: center;
        min-height: 100vh;
        padding-top: 3rem;
        padding-bottom: 3rem;
        pointer-events: none;
        &:focus {
          outline: 0;
        }
      }

      .ReactModal__Close {
        z-index: 1;
        position: absolute;
        right: -40px;
        top: -40px;
        width: 35px;
        height: 35px;
        pointer-events: all;
        transition: all .1s ease-in-out;
        ${mediaQueries.mediumDown(
          css`
            right: 0
          `
        )}
        @media (hover: hover) {
          &:hover {
            cursor: pointer;
            transform: scale(1.15);
            filter: drop-shadow(0 0 2px var(--yellow));
          }
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          height: 2px;
          width: 100%;
          top: 50%;
          left: 0;
          background: var(--white);
          margin-top: -2px;
        }

        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    `
    return (
      <>
        <Button onClick={this.handleOpen} aria-label="Více informací">
          <img src={iconInfo} />
        </Button>
        <ClassNames>
          {({ css }) => (
            <ReactModal
              isOpen={this.state.isOpen}
              shouldCloseOnOverlayClick={true}
              onRequestClose={this.handleClose}
              portalClassName={css(styles)}
              className="ReactModal"
            >
              <div className="ReactModal__Dialog">
                <div
                  className="ReactModal__Close"
                  onClick={this.handleClose}
                />
                {this.props.children}
              </div>
            </ReactModal>
          )}
        </ClassNames>
      </>
    )
  }
}
