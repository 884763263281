import * as React from "react"
import { css } from "@emotion/react"
import { mediaQueries } from "./breakpoints"

interface Props {
  children: React.ReactNode
  id?: string
  className?: string
  last?: boolean
}

const lastStyles = css`
  ${mediaQueries.smallDown(
    css`
      padding-bottom: 0;
    `
  )}
`

export const Section = (props: Props) => {
  const sectionStyles = [
    css`
      padding-top: 3rem;
      padding-bottom: 3rem;
      &:last-of-type {
        ${mediaQueries.small(
          css`
            padding-bottom: 6rem;
          `
        )}
      }

      h2 {
        text-align: center;
      }
    `
  ]

  return (
    <div id={props.id} className={props.className} css={[sectionStyles, props.last && lastStyles]}>{props.children}</div>
  )
}
