import React from "react"
import styled from "@emotion/styled"
import { Container } from "./Container"
import { useStaticQuery, graphql } from "gatsby"

const AlertMessage = styled("footer")`
  background-color: var(--black);
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--yellow);
`


export default () => {
  const data = useStaticQuery(graphql`
    query alertMessageQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "settings" } }) {
        frontmatter {
          alertMessage
        }
      }
    }
  `)

  return (
    <>
      {data.markdownRemark.frontmatter.alertMessage &&
      data.markdownRemark.frontmatter.alertMessage.length ? (
        <AlertMessage>
          <Container>
            <strong>{data.markdownRemark.frontmatter.alertMessage}</strong>
          </Container>
        </AlertMessage>
      ) : null }
    </>
  )
}
